import styled, { css } from 'styled-components';

import { A, Caption, H1, H2, HHero, Text1 } from 'src/components/Text';
import { ErrorNotificationWrapper } from 'src/components/ErrorNotification/error-notification-styled';
import { smlMedia, mdMedia, retinaMedia, themeColor } from 'src/theme/utils';

export const StepWrapper = styled.div`
  background: ${themeColor('primary0')};
  display: flex;
  position: absolute;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;

  & .md-only {
    display: none;
  }

  ${smlMedia(css`
    flex-direction: row;
  `)}

  ${mdMedia(css`
    flex-direction: row;

    & .sm-only {
      display: none;
    }

    & .md-only {
      display: block;
    }
  `)}
`;

export const StepApps = styled.div`
  order: 1;
  background: url(/images/${process.env.NEXT_PUBLIC_MIRROR_ID}/bg.png) no-repeat;
  background-position: center;
  background-size: cover;

  ${retinaMedia(css`
    background: url(/images/${process.env.NEXT_PUBLIC_MIRROR_ID}/bg@2x.png)
      no-repeat;
    background-position: center;
    background-size: cover;
  `)}

  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  min-height: 50vh;

  &::after {
    content: '';
    display: block;
  }

  ${smlMedia(css`
    order: 2 !important;
    flex: 0 0 50%;

    &::after {
    }
  `)}

  ${mdMedia(css`
    order: 2;
    flex: 0 0 50%;

    &::after {
    }
  `)}
`;

export const StepChoices = styled.div`
  order: 2;
  flex: 1 0 auto;
  padding: 21px 20px 40px;
  position: relative;

  ${HHero} {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: -0.3px;
  }

  ${HHero}, ${H1}, ${H2}, ${Text1}, ${Caption} {
    color: ${themeColor('black80')};
  }

  ${Text1} {
    margin-top: 24px;
    margin-bottom: 20px;
  }

  ${Caption} {
    max-width: 335px;
  }

  ${A} {
    color: ${themeColor('primary70')};
    white-space: nowrap;
  }

  ${smlMedia(css`
    order: 1 !important;
  `)}

  ${mdMedia(css`
    order: 1;
    flex: 0 0 50%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-left: 104px;

    ${H2} {
      margin-top: 39px;
      margin-bottom: 24px;
    }

    ${Caption} {
      max-width: 416px;
    }
  `)}
`;

export const GoalBtn = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  flex: 1 1 50%;
  padding: 14px;
  border-radius: 9px;
  background-color: ${themeColor('primary70')};

  &[disabled] {
    cursor: not-allowed;
  }

  ${mdMedia(css`
    flex: 0 1 218px;
  `)}

  p {
    color: ${themeColor('black0')};
  }
`;

export const GoalBtns = styled.div`
  display: flex;
  flex-direction: column;

  ${GoalBtn}:not(:first-child) {
    margin-top: 1rem;
  }

  ${mdMedia(css`
    flex-direction: row;

    ${GoalBtn}:not(:first-child) {
      margin-left: 1rem;
      margin-top: 0;
    }
  `)}
`;

export const StepChoicesContent = styled.div`
  max-width: 335px;
  width: 100%;
  margin: 0 auto;

  & ${ErrorNotificationWrapper} {
    margin: 1rem 0 0;
  }

  ${smlMedia(css`
    margin: 0 64px;
    max-width: 60%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  `)}

  ${mdMedia(css`
    margin: 0;
    max-width: 60%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    & ${ErrorNotificationWrapper} {
      position: absolute;
      top: calc(100% + 1rem);
      left: 0;
      width: 100%;
      margin: 0;
    }
  `)}
`;

export const PrivacyWrapper = styled.div`
  display: flex;
  margin: 1.5rem auto 0;

  ${mdMedia(css`
    margin: 2.5rem 0 0;
  `)}
`;

export const PrivacyCheckboxSVG = styled.svg`
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 0.5rem;
  cursor: pointer;

  & > path {
    stroke: transparent;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  & > rect {
    stroke: ${themeColor('black20')};
    stroke-width: 2px;
  }

  &.checked {
    & > path {
      stroke: ${themeColor('primary70')};
    }

    & > rect {
      stroke: transparent;
    }
  }
`;

export const EmailConfirmWrapper = styled.div`
  display: flex;
  margin: 1rem auto 0;

  ${smlMedia(css`
    margin-left: 0;
  `)}

  ${mdMedia(css`
    margin: 1rem 0 0;
  `)}
`;

export const EmailConfirmCheckboxSVG = styled.svg`
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 0.5rem;
  cursor: pointer;

  & > path {
    stroke: transparent;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  & > rect {
    stroke: ${themeColor('black20')};
    stroke-width: 2px;
  }

  &.checked {
    & > path {
      stroke: ${themeColor('primary70')};
    }

    & > rect {
      stroke: transparent;
    }
  }
`;
